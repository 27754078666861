var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
var isMobile = window.innerWidth < 600;
export var avatarLauncherStyles = function (theme, loader, options) {
    if (loader === void 0) { loader = { mini: false }; }
    if (options === void 0) { options = {}; }
    var isAvatarMobileLauncher = isMobile || loader.mini;
    var isBubble = options === null || options === void 0 ? void 0 : options.isBubble;
    var AVATAR_WIDTH = isBubble ? '90px' : isAvatarMobileLauncher ? '56px' : '60px';
    var AVATAR_PADDING = isBubble ? '0px' : isAvatarMobileLauncher ? '40px' : '75px';
    return {
        avatarLauncherRoot: __assign({ transform: 'none', height: 'auto' }, (isMobile
            ? {
                right: 10,
                bottom: 10,
            }
            : {})),
        avatarLauncherContainer: __assign({ display: 'flex', gap: '10px', background: get(theme, 'colors.brand'), color: get(theme, 'colors.textOnBrand'), height: isAvatarMobileLauncher ? '60px' : 'auto', paddingLeft: AVATAR_PADDING, paddingTop: isAvatarMobileLauncher ? '0px' : '5px', paddingBottom: isAvatarMobileLauncher ? '0px' : '5px', paddingRight: isAvatarMobileLauncher ? '5px' : '10px', boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px, rgba(0, 0, 0, 0.05) -6px 1px 11px", justifyContent: 'space-between', alignItems: 'center', position: 'relative', transition: 'transform 200ms', ':hover': {
                transform: 'scale(1.07)',
            } }, (isBubble ? {
            position: 'absolute',
            bottom: "".concat(parseInt(AVATAR_WIDTH) + 30, "px"),
            padding: '10px',
            right: 0,
            minWidth: '90px',
            textAlign: 'center',
            '::before': {
                content: '""',
                width: '0px',
                height: '0px',
                position: 'absolute',
                borderLeft: "10px solid ".concat(get(theme, 'colors.brand')),
                borderRight: '10px solid transparent',
                borderTop: "10px solid ".concat(get(theme, 'colors.brand')),
                borderBottom: '10px solid transparent',
                bottom: '-20px',
                left: '10px'
            }
        } : {})),
        closeIcon: {
            top: '-6px !important',
            right: '-6px !important',
        },
        avatarLauncherAvatarContainer: __assign({ position: 'absolute', left: isAvatarMobileLauncher ? '-25px' : '5px', borderRadius: '100%', border: "2px solid ".concat(get(theme, 'colors.brand')), background: get(theme, 'colors.brand'), lineHeight: 0 }, (isBubble ? {
            position: 'absolute',
            bottom: "".concat(-1 * (parseInt(AVATAR_WIDTH) + 30), "px"),
            right: "".concat(parseInt(AVATAR_WIDTH) / 2, "px"),
            left: 'auto'
        } : {})),
        avatarLauncherAvatarImage: {
            borderRadius: '100%',
            height: AVATAR_WIDTH,
            width: AVATAR_WIDTH,
        },
        avatarLauncherVideo: {
            borderRadius: '100%',
            height: AVATAR_WIDTH,
            width: AVATAR_WIDTH,
            position: 'absolute',
            left: 0,
            top: 0
        },
        avatarLauncherLoading: {
            position: 'absolute',
            left: isAvatarMobileLauncher ? '-15px' : '15px',
        },
        avatarLauncherBody: {
            maxWidth: '120px',
            lineHeight: 1.2,
            fontSize: isMobile ? '10px' : '12px',
        },
        avatarLauncherTitle: {
            fontSize: isMobile ? '12px' : '14px',
            marginBottom: '2px',
            marginTop: '5px',
        },
        avatarLauncherMessage: {
            opacity: 0.9,
            marginBottom: '5px',
        },
    };
};
