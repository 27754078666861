import { addStylesToRootLauncher, appendChild, appendToRoot, createElement, getRootLauncher } from './utils';
import { getItem } from 'conversional-persistent-storage';
import { addCloseIcon, addLoadingIndicator, LAUNCHER_HIDE_KEY } from './general';
import { avatarLauncherStyles } from '../styles/avatarLauncher.styles';
// @ts-ignore
import * as domUtils from 'conversional-utils/dom';
var theme = null;
var styles = null;
var DEFAULT_LAUNCHER_VIDEO_TIMEOUT = 3000;
export var renderAvatarLauncher = function (loader, theme) {
    appendToRoot(createAvatarLauncherContainer(loader, theme, {}));
};
var PREFIX_CLASS = 'avatar-launcher-';
var getPrefixedClassName = function (className) { return "".concat(PREFIX_CLASS).concat(className); };
export function createAvatarLauncherContainer(loader, _theme, options) {
    theme = _theme;
    styles = avatarLauncherStyles(theme, loader, options);
    addStylesToRootLauncher(styles.avatarLauncherRoot);
    var launcherContainer = createElement({
        tagName: 'div',
        uniqueId: "".concat(getPrefixedClassName('container')),
        styles: styles.avatarLauncherContainer,
        backgroundColor: loader.backgroundColor
    });
    if (getItem(LAUNCHER_HIDE_KEY))
        getRootLauncher().style.display = 'none';
    var closable = loader.closable, reloadOnClose = loader.reloadOnClose;
    closable &&
        addCloseIcon(launcherContainer, {
            reloadOnClose: reloadOnClose,
            styles: styles.closeIcon
        });
    addLoadingIndicator(launcherContainer, {
        styles: styles.avatarLauncherLoading
    });
    addAvatarContainer(launcherContainer, loader);
    addLauncherTexts(launcherContainer, loader);
    return launcherContainer;
}
export function addAvatarContainer(container, loader) {
    var avatarContainer = createElement({
        uniqueId: getPrefixedClassName('avatar-container'),
        styles: styles.avatarLauncherAvatarContainer
    });
    addAvatarImage(avatarContainer, loader);
    addAvatarVideo(avatarContainer, loader);
    appendChild(container, avatarContainer);
}
export function addAvatarImage(avatarContainer, loader) {
    var avatarImage = createElement({
        tagName: 'img',
        uniqueId: 'avatar-image',
        styles: styles.avatarLauncherAvatarImage,
        attributes: {
            src: loader.image,
            role: 'avatar-image'
        }
    });
    appendChild(avatarContainer, avatarImage);
}
export function addAvatarVideo(avatarContainer, loader) {
    if (!loader.video)
        return;
    var attributes = {
        src: loader.video,
        muted: true,
        playsinline: true,
        role: 'avatar-video'
    };
    if (loader.loopVideo)
        attributes.loop = true;
    var avatarVideo = createElement({
        tagName: 'video',
        uniqueId: 'avatar-video',
        styles: styles.avatarLauncherVideo,
        attributes: attributes
    });
    var timeout = loader.playVideoTimeout || DEFAULT_LAUNCHER_VIDEO_TIMEOUT;
    var played = false;
    var playVideo = function () {
        setTimeout(function () {
            if (played)
                return;
            avatarVideo.play().then(function () {
                played = true;
                domUtils.removeUniversalUserEventListener(playVideo);
            }).catch(function () { });
        }, timeout);
    };
    domUtils.addUniversalUserEventListener(playVideo);
    appendChild(avatarContainer, avatarVideo);
}
export function addLauncherTexts(container, loader) {
    var uniqueId = getPrefixedClassName('body');
    var textsContainer = createElement({
        styles: styles.avatarLauncherBody,
        uniqueId: uniqueId
    });
    addLauncherTitle(textsContainer, loader);
    addLauncherMessage(textsContainer, loader);
    appendChild(container, textsContainer);
}
export function addLauncherTitle(container, loader) {
    var uniqueId = getPrefixedClassName('title');
    var launcherTitle = createElement({
        uniqueId: uniqueId,
        styles: styles.avatarLauncherTitle,
        innerHTML: loader.title
    });
    appendChild(container, launcherTitle);
}
export function addLauncherMessage(container, loader) {
    var uniqueId = getPrefixedClassName('message');
    var launcherMessage = createElement({
        uniqueId: uniqueId,
        styles: styles.avatarLauncherMessage,
        innerHTML: loader.message
    });
    appendChild(container, launcherMessage);
}
