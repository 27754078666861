import get from 'lodash/get';
// @ts-ignore
import * as eventUtils from 'conversional-utils/event';
import { addEventListenerToRoot } from '../actions/events';
import { renderAvatarLauncher } from './avatarLauncher';
import { renderIconLauncher } from './iconLauncher';
import { generalStyles } from '../styles/general.styles';
import { applyDynamicStyling } from "../styles/utils";
import { appendChild, createElement, emptyRendered } from './utils';
import { CONVERSIONAL_LAUNCHER_ROOT_ID } from '../constants';
import { renderBubbleLauncher } from './bubbleLauncher';
var DEFAULT_LAUNCHER = {
    render: renderIconLauncher,
};
var LAUNCHER_TYPES = {
    AvatarLauncher: {
        render: renderAvatarLauncher,
    },
    BubbleLauncher: {
        render: renderBubbleLauncher,
    },
    IconLauncher: renderIconLauncher,
    default: DEFAULT_LAUNCHER,
};
export function isLauncherEnabled() {
    return get(window, "conversionalWidget.config.iframes.LAUNCHER", true);
}
export var injectLauncherToHost = function (journeyConfig) {
    var loader = get(journeyConfig, 'callToAction.loader');
    var theme = journeyConfig.theme;
    if (!loader || !isLauncherEnabled()) {
        return;
    }
    var rootLauncher = createElement({
        tagName: 'div',
        id: CONVERSIONAL_LAUNCHER_ROOT_ID,
        styles: [
            generalStyles().launcherRoot,
            generalStyles()["position-".concat(loader.position || 'right')],
        ],
    });
    var launcherTargetContainer = loader.containerId ? (document.getElementById(loader.containerId) || document.body) : document.body;
    appendChild(launcherTargetContainer, rootLauncher);
    var launcherType = LAUNCHER_TYPES[loader.type || 'default'] || DEFAULT_LAUNCHER;
    addEventListenerToRoot('click', 'onRootClick');
    var launcherCreationDelay = get(journeyConfig, 'iframes.LAUNCHER.delay', 0);
    setTimeout(function () {
        launcherType.render(loader, theme);
    }, launcherCreationDelay);
};
export var removeFromDOM = function () {
    var launcherRoot = document.getElementById(CONVERSIONAL_LAUNCHER_ROOT_ID);
    launcherRoot && launcherRoot.remove();
    emptyRendered();
};
export var removeIframesFromDOM = function () {
    var iframes = document.querySelectorAll('[id^="iframe-conversional"]');
    iframes.forEach(function (iframe) { return iframe.remove(); });
    window.conversionalIframesCreated = false;
};
function initializeDOMForLauncher(config) {
    if (!isLauncherEnabled())
        return;
    injectLauncherToHost(config);
    applyDynamicStyling(config);
}
function appendToLauncherObject(settings, journeyConfigData) {
    window.conversionalLauncher.journeyConfig = journeyConfigData;
    window.conversionalLauncher.journeySettings = settings;
}
export function initializeLauncher(settings, journeyConfigData) {
    var journeyConfig = journeyConfigData.configuration;
    appendToLauncherObject(settings, journeyConfigData);
    initializeDOMForLauncher(journeyConfig);
    // For public API usage
    eventUtils.emitEvent('conversionalReady', window.conversionalLauncher);
}
