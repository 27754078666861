var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import { getHub, MESSAGETYPES } from 'conversional-iframe-communication';
import { urlHelpers } from 'conversional-api';
import isEmpty from 'lodash/isEmpty';
// @ts-ignore
import * as domUtils from 'conversional-utils/dom';
import { getItem, setItem } from 'conversional-persistent-storage';
var INIT_PATH = 'conversionalLauncher.journeyConfig';
var SETTINGS_PATH = 'conversionalLauncher.journeySettings';
var CONVERSIONAL_REFERRER_STORAGE_KEY = 'hostReferrer';
export var getSettings = function () { return get(window, SETTINGS_PATH, null); };
export var getConfig = function () {
    var config = get(window, "".concat(INIT_PATH, ".configuration"), null);
    if (isEmpty(config))
        return null;
    config.updated = get(window, "".concat(INIT_PATH, ".updated"), null);
    return config;
};
export var getJourneyId = function () { return getConfig().id || '(not set)'; };
export var trackingEnabled = function () {
    return get(window, 'conversionalWidget.config.tracking.enabled', true);
};
export var disableCookies = function () {
    var settings = getSettings();
    var cookieKeys = ['noCookies', 'noCookie', 'nocookies', 'nocookie'];
    return cookieKeys.some(function (key) { return settings[key] === 'true'; });
};
var lastHostInfo = '';
export var applyExternalData = function () {
    var _a;
    // Skip sending identical configuration
    if (lastHostInfo === JSON.stringify(window.conversionalWidget)) {
        return;
    }
    // Read Data
    // DEPRECATED FUNCTION - reformat data to support Küche&Co with Questionnaire and CLosure keys
    var check = (_a = window.conversionalWidget) === null || _a === void 0 ? void 0 : _a.configuration;
    var data;
    if (check === null || check === void 0 ? void 0 : check.Questionnaire) {
        var _b = check.Questionnaire.Closure, meta = _b.meta, customer = __rest(_b, ["meta"]);
        data = {
            data: {
                Customer: customer,
                meta: meta,
            },
        };
    }
    else {
        data = window.conversionalWidget;
    }
    data = __assign(__assign({}, (data || {})), { hostQueryParams: urlHelpers.parseQueryString() });
    try {
        var hostReferrer = getItem(CONVERSIONAL_REFERRER_STORAGE_KEY);
        if (!hostReferrer && domUtils.isExternalReferrer()) {
            hostReferrer = document.referrer;
            setItem(CONVERSIONAL_REFERRER_STORAGE_KEY, hostReferrer);
        }
        data.hostReferrer = hostReferrer;
    }
    catch (e) {
        //@ts-ignore
    }
    // send Message
    if (data) {
        getHub().publish(MESSAGETYPES.SET_EXTERNAL, data);
    }
};
export var getScriptConfig = function () {
    var url = new URL(document.currentScript.getAttribute('src'));
    var scriptParams = Object.fromEntries(url.searchParams);
    return scriptParams;
};
