var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-ignore
import * as callToActionUtils from 'conversional-utils/callToActions';
// @ts-ignore
import { getJourneySettings } from 'conversional-environment/settings';
import { addPreloaderIframe, getRootLauncher, parseQueryString, runLauncher } from './dom/utils';
import { addLauncherStyles } from './styles';
import { getJourneyConfig } from './api';
import { configureUrl } from './url';
import { deepMerge } from './functions';
import { initializeLauncher, removeFromDOM, removeIframesFromDOM } from './dom';
import { checkInlineWidgetAndRenderLoading, renderLoading } from './dom/loading';
import createIframes from './createIframes';
import { waitForClient, waitForUserInteraction } from './wait';
import { processCTAClick } from './actions/actions';
var _started = false;
// Expose public endpoint, needs to be available right from the start
if (!window.conversionalLauncher) {
    window.conversionalLauncher = {
        onReady: function () { return new Promise(function (resolve, reject) {
            waitForClient(function (data) {
                window.conversionalLauncher.isReady = true;
                resolve(data);
            }, reject);
        }); }
    };
}
window.conversionalLauncher = __assign(__assign({}, window.conversionalLauncher), { destroy: function () {
        removeFromDOM();
        removeIframesFromDOM();
        _started = false;
    }, reload: function () {
        removeFromDOM();
        removeIframesFromDOM();
        _started = false;
        bootstrap();
    } });
var bootstrap = function () {
    return new Promise(function (resolve, reject) {
        var urlParams = parseQueryString();
        if (_started) {
            return;
        }
        _started = true;
        runLauncher(function (settings) {
            if (getRootLauncher()) {
                return;
            }
            var isLazyMode = function () { return getJourneySettings().bootstrap === 'lazy'; };
            window.conversionalLauncher.journeySettings = settings;
            window.conversionalLauncher.lazy = isLazyMode();
            // Recognizing flash mode before fetching journey config
            if (!isLazyMode()) {
                // Just an iframe to fetch the main React bundle so it gets cached for the next loads
                addPreloaderIframe();
            }
            getJourneyConfig(settings).then(function (journeyConfig) {
                // Deep merging passed and fetched values to make blackList and whiteList parameters overwritable
                var configuration = {
                    configuration: deepMerge(journeyConfig.configuration, (window.conversionalWidget && window.conversionalWidget.config) || {}),
                }.configuration;
                if (!configureUrl(configuration)) {
                    return false;
                }
                addLauncherStyles();
                initializeLauncher(settings, journeyConfig);
                callToActionUtils.onCTAClick(processCTAClick);
                callToActionUtils.createStyleTagForCTAs();
                if (urlParams.dynamic || urlParams.openWidget) {
                    renderLoading(true);
                }
                checkInlineWidgetAndRenderLoading(configuration);
                renderLoading(configuration.loading);
                startMainWidget(configuration).then(resolve);
            });
        });
    });
};
/**
 * If clients are concernec by the Google Pagespeed Ranking the actual loading of the tool can be delayed until the first user interaction
 * This is an opt-in feature because it may diminish user experience, especially on mobile devices and inline rendering where no user interaction may take place.
 */
function startMainWidget(configuration) {
    return new Promise(function (resolve, reject) {
        if (!configuration.delayForPageSpeed) {
            return createIframes().then(resolve);
        }
        return waitForUserInteraction(function () { return createIframes().then(resolve); });
    });
}
/**
 * Processes a CTA click by either executing it directly or persisting the click information to be processed once the client is ready.
 */
export default function () {
    return new Promise(function (resolve, reject) {
        if (document.body) {
            return bootstrap().then(resolve);
        }
        window.addEventListener('DOMContentLoaded', function () {
            bootstrap().then(resolve);
        });
    });
}
